import './App.css';
import React, { useState, useEffect } from "react";
import { Stack, Text } from "@fluentui/react";
import { ImageArea } from "./ImageArea";
import { Button } from "./Button";
import { PixelToImageDataForNpu, codePointsToString } from "./utility";

function App() {

  var processingStartTime: number | undefined;
  const [processingTimeResult, setProcessingTimeResult] = useState<string | undefined>(undefined);

  const [inputText, setInputText] = useState<string | undefined>(undefined);
  const [outputImage, setOutputImage] = useState<string | undefined>(undefined);
  const [startProcessing, setStartProcessing] = useState<boolean | undefined>(undefined);
  const [backend, setBackend] = useState<string | undefined>('Azure');
  const [NPUOption, setNPUOption] = useState<number>(0);

  const runStableDiffusionAsync = async (backend: string | undefined, textinput: string) => {    
    let forceToUseAzure = backend === "Azure";
    // @ts-ignore
    var output = await NxtPS.StableDiffusionProcess(forceToUseAzure, textinput);
    if(output.IsBase64[0] === 1) {
      return codePointsToString(output.Array);
    }
    else {
    const outputImage = await PixelToImageDataForNpu(output.Array, output.Dimension[0], output.Dimension[1]);
    return await outputImage.getBase64Async(outputImage.getMIME());
    }
  };

  const checkNPU = async() => {
    // @ts-ignore
    var output = await NxtPS.isNpuSupported();
    setNPUOption(output);
  }

  useEffect(() => {
    document.title = "Stable Diffusion";
    checkNPU();
 }, []);

  useEffect(() => {
    if (startProcessing === true) {
      (async () => {        
        processingStartTime = Date.now();
        setProcessingTimeResult(undefined);
        let str = "";
        if(inputText !== undefined) {
          str = await runStableDiffusionAsync(backend, inputText);
        }
        var now = Date.now();
        var diff = (now - processingStartTime)/1000;
        setProcessingTimeResult(diff.toString());
        processingStartTime = undefined;
        setOutputImage(str);
        setStartProcessing(false);
      })();
    }

  }, [startProcessing]);

  return (
    <Stack horizontal style={{ display: "flex", justifyContent: "center", height: "100%" }}>
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          height: "100%",
          padding: "20px",
          maxWidth: "150vh",
          flex: "1",
        }}
      >

        <Stack style={{ flex: "1", maxWidth: "40vw", minWidth: "360px" }}>
          <Text style={{ color: "white", fontSize: "16pt", marginBottom: "10px" }}>Stable Diffusion</Text>
          <Stack horizontal style={{ alignItems: "center", marginBottom:"3px" }}>
            <input style={{width: "100%", height: 25, borderRadius: 4 }}
             value={inputText}
             onChange={e => setInputText(e.target.value)}
             placeholder=' Type text here...' />
            <Button
              iconName={""}
              text={"Generate"}
              style={{ margin: "3px" }}
              checked={true}
              onClick={() => {
                setStartProcessing(true);
              }}
            />
          </Stack>
          <Stack horizontal style={{ alignItems: "center", marginBottom:"3px" }}>
          <label style={{color: "#FFFFFF", margin:"3px", marginRight:"10px"}}>  
            <input type="radio" value="Azure" checked={backend === 'Azure'} onChange={e => setBackend(e.target.value)} />          
          Azure 
          </label>
          <label style={{color: "#FFFFFF", display: NPUOption === 1 ? 'inline' : 'none'}}>
            <input type="radio" value="LocalNPU" checked={backend === 'LocalNPU'} onChange={e => setBackend(e.target.value)} />
            Local NPU
          </label>
          <label style={{color: "#FFFFFF", display: NPUOption === 2 ? 'inline' : 'none'}}>
            <input type="radio" value="RDP_NPU" checked={backend === 'RDP_NPU'} onChange={e => setBackend(e.target.value)} />
            NPU over RDP
          </label>
          </Stack>
          <ImageArea
            style={{
              aspectRatio: "1 / 1",
              maxWidth: "40vw",
              minWidth: "360px",
              marginBottom: "10px"
            }}
            showProcessing={startProcessing}
            imageUrl={outputImage}
            pickItemText='Type text to generate image'
          />
          <Text style={{ color: "white", fontSize: "14pt", textAlign: "right", top: "6px" }}>Time: {processingTimeResult === undefined ? "---" : processingTimeResult.toString()}</Text>

        </Stack>
      </Stack>
    </Stack>
  );
}

export default App;
