import { Stack, PrimaryButton, FontIcon, DefaultButton, useTheme } from "@fluentui/react";

type ButtonProps = {
  iconName?: string;
  text?: string;
  style?: React.CSSProperties;
  checked?: boolean;
  onClick?: React.MouseEventHandler<PrimaryButton>;
};

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const theme = useTheme();

  return (
    <DefaultButton
      styles={{
        root: {
          height: "30px",
          width: "100px",
          borderRadius: theme.effects.roundedCorner4,
          boxShadow: theme.effects.elevation8,
          color: theme.semanticColors.buttonText,
          backgroundColor: theme.semanticColors.buttonBackgroundHovered,
          border: "0px"
        },
        rootHovered: {
          color: theme.semanticColors.buttonText,
          border: "4px solid",
          borderColor: theme.semanticColors.accentButtonBackground,
        },
        rootCheckedHovered: {
          color: theme.semanticColors.accentButtonText,
          backgroundColor: theme.palette.themePrimary,
        },
        rootPressed: {
          backgroundColor: theme.palette.themeTertiary,
        },
        rootChecked: {
          color: theme.semanticColors.accentButtonText,
          backgroundColor: theme.palette.themePrimary,
        },
      }}
      toggle
      checked={props.checked}
      onClick={props.onClick}
      style={props.style}
    >
      {props.iconName ? <FontIcon iconName={props.iconName} style={{ marginRight: "5px" }} /> : <></>}
      {props.text}
    </DefaultButton>
  );
};
