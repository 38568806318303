import Jimp from 'jimp/browser/lib/jimp';

export function sleep(delayMs: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delayMs));
}

export function clip(value: number, min: number = 0, max: number = 255) {
  return Math.min(Math.max(value, min), max);
}

export function pixelToFloat(pixel: number) {
  return (2.0 / 255.0) * pixel - 1.0;
}

export function floatToPixel(float: number) {
  return (255.0 / 2.0) * (float + 1.0);
}

export async function PixelToImageDataForNpu(data: Int32Array, width: number, height: number) {
  const outputImage = await Jimp.read(width, height);

  var i = 0;
  // Populate each pixel of the output image with the output data
  outputImage.scan(
    0,
    0,
    outputImage.bitmap.width,
    outputImage.bitmap.height,
    function (_x, _y, idx) {
      this.bitmap.data[idx] = data[i];
      this.bitmap.data[idx + 1] = data[i + 1];
      this.bitmap.data[idx + 2] = data[i + 2];
      this.bitmap.data[idx + 3] = 255;

      i += 3;
    }
  );

  return outputImage;
}

  // This is to convert large byte array to string. 
  export function codePointsToString(cps:Array<number>) {
    let rs = [];
    let batch = 32767; // Supported 'all' browsers
    for (let i = 0; i < cps.length; ){
      let e = i + batch;
      // Build batch section, defer to Array.join.
      rs.push(String.fromCodePoint.apply(null, cps.slice(i, e)));
      i = e;
    }
    let json = JSON.parse(rs.join(''));
    return "data:image/png;base64," + json[0].generated_image;
  }
